import { ClassNames } from '@emotion/react'
import { Box } from '@chakra-ui/react'
import Divider from '../elements/divider'
import Inner from '../elements/inner'
import Content from '../elements/content'
import Svg from './svg'
import { UpDown, UpDownWide } from '../styles/animations'
// @ts-ignore
import ProjectsMDX from '../sections/projects'

const Projects = ({
  offset,
  factor = 2,
}: {
  offset: number
  factor?: number
}) => (
  <ClassNames>
    {({ css }) => (
      <Box>
        <Divider
          css={css({
            background:
              'linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%)',
            clipPath: 'polygon(0 15%, 100% 25%, 100% 85%, 0 75%)',
          })}
          speed={-0.2}
          offset={1.1}
          factor={factor}
        />
        <Content speed={0.4} offset={offset + 0.2} factor={factor}>
          <Inner>
            <Box
              display="grid"
              gridGap={[4, 4, 4, 5]}
              gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
              css={css({
                h2: {
                  gridColumn: '-1/1',
                  color: 'white',
                },
              })}
            >
              <ProjectsMDX />
            </Box>
          </Inner>
        </Content>
        <Divider speed={0.1} offset={offset} factor={factor}>
          <UpDown>
            <Svg icon="box" width={6} color="cyan" left="85%" top="75%" />
            <Svg
              icon="upDown"
              width={8}
              color="teal.400"
              left="70%"
              top="20%"
            />
            <Svg
              icon="triangle"
              width={8}
              stroke="Highlight"
              color="orange.300"
              left="25%"
              top="5%"
            />
            <Svg
              icon="circle"
              hiddenMobile
              width={24}
              color="cyan.300"
              left="17%"
              top="60%"
            />
          </UpDown>
          <UpDownWide>
            <Svg
              icon="arrowUp"
              hiddenMobile
              width={16}
              color="green.300"
              left="20%"
              top="90%"
            />
            <Svg
              icon="triangle"
              width={12}
              stroke="Highlight"
              color="cyan"
              left="90%"
              top="30%"
            />
            <Svg
              icon="circle"
              width={16}
              color="yellow.A400"
              left="70%"
              top="90%"
            />
            <Svg
              icon="triangle"
              hiddenMobile
              width={16}
              stroke="blackAlpha.300"
              color="teal.400"
              left="18%"
              top="75%"
            />
            <Svg icon="circle" width={6} color="cyan" left="75%" top="10%" />
            <Svg
              icon="upDown"
              hiddenMobile
              width={8}
              color="green.300"
              left="45%"
              top="10%"
            />
          </UpDownWide>
          <Svg
            icon="circle"
            hiddenMobile
            width={6}
            color="cyan"
            left="4%"
            top="20%"
          />
          <Svg icon="circle" width={12} color="pink" left="80%" top="60%" />
          <Svg icon="box" width={6} color="orange.300" left="10%" top="10%" />
          <Svg icon="box" width={12} color="yellow.A400" left="29%" top="26%" />
          <Svg
            icon="hexa"
            width={16}
            stroke="WindowFrame"
            color="red.400"
            left="75%"
            top="30%"
          />
          <Svg
            icon="hexa"
            width={8}
            stroke="blue.700"
            color="yellow.A400"
            left="80%"
            top="70%"
          />
        </Divider>
      </Box>
    )}
  </ClassNames>
)

export default Projects
