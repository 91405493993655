import React from 'react'
import { Box } from '@chakra-ui/react'

const Footer = () => {
  return (
    <Box as="footer">
      Copyright {new Date().getFullYear()} &copy; christran.org <br /> All
      rights reserved.
      <br />
    </Box>
  )
}

export default Footer
