import * as u from '@jsmanifest/utils'
import React from 'react'
import produce, { Draft } from 'immer'
import { css } from '@emotion/react'
import type { InputProps } from '@chakra-ui/react'
import {
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Image,
  Input,
  Link,
  Progress,
  CircularProgress,
  Text,
} from '@chakra-ui/react'
import type { BoxProps } from 'components/Box'
import Box from 'components/Box'
import createUpdateState from 'utils/updateState'

export interface ContactFormProps {
  //
}

const Field = React.memo(
  ({
    wrapperProps,
    ...inputProps
  }: InputProps & { wrapperProps?: BoxProps }) => {
    return (
      <Box my={10} {...wrapperProps}>
        <Input {...inputProps} />
      </Box>
    )
  },
  (p, np) => p.value === np.value,
)

function ContactForm(props: React.PropsWithChildren<ContactFormProps>) {
  const [values, setValues] = React.useState<Record<string, any>>({})

  const onChange = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      setValues((prevValues) => ({
        ...prevValues,
        [evt.target.name]: evt.target.value,
      }))
    },
    [],
  )

  const onSubmit = React.useCallback(
    (evt: React.FormEvent<HTMLFormElement>) => {
      //
    },
    [],
  )

  const fields = ['name', 'email', { name: 'message', type: 'textarea' }] as (
    | InputProps
    | string
  )[]

  return (
    <form action="mailto:pfftdammitchris@gmail.com">
      {fields.map((field) => {
        const name = u.isObj(field) ? field.name : field
        return (
          <Field
            name={name}
            value={values[name]}
            onChange={onChange}
            {...(u.isObj(field) ? field : undefined)}
          />
        )
      })}
    </form>
  )
}

export default ContactForm
