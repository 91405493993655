import { ParallaxLayer } from '@react-spring/parallax'
import { css } from '@emotion/react'

export interface ContentProps {
  css?: typeof css
  speed: number
  offset: number
  children: React.ReactNode
  className?: string
  factor?: number
}

const Content = ({
  css: cssProp,
  speed,
  offset,
  children,
  className = ``,
  factor = 1,
}: ContentProps) => (
  <ParallaxLayer
    css={css({
      padding: '30px 40px 40px 50px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 50,
      width: 'auto !important',
      ...cssProp,
    })}
    speed={speed}
    offset={offset}
    factor={factor}
    className={className}
  >
    {children}
  </ParallaxLayer>
)

export default Content
