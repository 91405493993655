import React from 'react'
import { ClassNames } from '@emotion/react'
import { Image } from '@chakra-ui/react'
import avatarImg from 'resources/images/avatar.png'
import Divider from '../elements/divider'
import Inner from '../elements/inner'
import Content from '../elements/content'
import Svg from './svg'
import { UpDown, UpDownWide } from '../styles/animations'
// @ts-ignore
import Intro from '../sections/intro'

const Hero = ({ offset, factor = 1 }: { offset: number; factor?: number }) => (
  <div id="contact-wave">
    <Divider speed={0.2} offset={offset} factor={factor}>
      <UpDown>
        <Svg
          icon="triangle"
          hiddenMobile
          width={48}
          stroke="ActiveBorder"
          color="orange"
          left="10%"
          top="20%"
        />
        <Svg
          icon="hexa"
          width={48}
          stroke="ButtonFace"
          color="red"
          left="60%"
          top="70%"
        />
        <Svg icon="box" width={6} color="transparent" left="60%" top="15%" />
      </UpDown>
      <UpDownWide>
        <Svg
          icon="arrowUp"
          hiddenMobile
          width={16}
          color="blue"
          left="80%"
          top="10%"
        />
        <Svg
          icon="triangle"
          width={12}
          stroke="aquamarine"
          color="brightest"
          left="90%"
          top="50%"
        />
        <Svg
          icon="circle"
          width={16}
          color="transparent"
          left="70%"
          top="90%"
        />
        <Svg
          icon="triangle"
          width={16}
          stroke="cyan.800"
          color="teal.400"
          left="30%"
          top="65%"
        />
        <Svg
          icon="cross"
          width={16}
          stroke="yellow.800"
          color="purple.400"
          left="28%"
          top="15%"
        />
        <Svg icon="circle" width={6} color="teal.400" left="75%" top="10%" />
        <Svg
          icon="upDown"
          hiddenMobile
          width={8}
          color="teal.400"
          left="45%"
          top="10%"
        />
      </UpDownWide>
      <Svg
        icon="circle"
        hiddenMobile
        width={24}
        color="transparent"
        left="5%"
        top="70%"
      />
      <Svg icon="circle" width={6} color="blue.400" left="4%" top="20%" />
      <Svg icon="circle" width={12} color="teal.400" left="50%" top="60%" />
      <Svg icon="upDown" width={8} color="blue.400" left="95%" top="90%" />
      <Svg
        icon="upDown"
        hiddenMobile
        width={24}
        color="purple.400"
        left="40%"
        top="80%"
      />
      <Svg
        icon="triangle"
        width={8}
        stroke="facebook.300"
        color="purple.400"
        left="25%"
        top="5%"
      />
      <Svg icon="circle" width={64} color="green" left="95%" top="5%" />
      <Svg
        icon="box"
        hiddenMobile
        width={64}
        color="purple"
        left="5%"
        top="90%"
      />
      <Svg icon="box" width={6} color="teal.400" left="10%" top="10%" />
      <Svg icon="box" width={12} color="teal.400" left="40%" top="30%" />
      <Svg
        icon="hexa"
        width={16}
        stroke="lightpink"
        color="purple.400"
        left="10%"
        top="50%"
      />
      <Svg
        icon="hexa"
        width={8}
        stroke="ActiveBorder"
        color="purple.400"
        left="80%"
        top="70%"
      />
    </Divider>
    <Content speed={0.4} offset={offset} factor={factor}>
      <ClassNames>
        {({ css }) => (
          <Inner
            css={css({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            })}
          >
            <Image boxSize={250} src={avatarImg} alt="Avatar" />
            <Intro />
          </Inner>
        )}
      </ClassNames>
    </Content>
  </div>
)

export default Hero
