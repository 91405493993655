import React from 'react'
import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import { useTheme } from '@chakra-ui/react'
import type { IParallax } from '@react-spring/parallax'
import { Parallax } from '@react-spring/parallax'
import Layout from 'components/Layout'
import Hero from 'components/hero'
import Projects from 'components/projects'
import About from 'components/about'
import Contact from 'components/contact'

const Homepage = ({ data, location }: PageProps) => {
  const parallax = React.useRef<IParallax>()

  return (
    <Layout backgroundImage="radial-gradient( circle farthest-corner at 83.7% 4.3%,  rgba(173,0,171,1) 0%, rgb(23, 42, 64) 70% )">
      <Parallax ref={parallax} pages={6}>
        <Hero offset={0} factor={1} />
        <About offset={1} factor={2} />
        <Projects offset={3} factor={1} />
        <Contact offset={4} factor={2} />
      </Parallax>
    </Layout>
  )
}

export const pageQuery = graphql`
  query SiteMetadataQuery {
    site {
      siteMetadata {
        siteTitle
        siteDescription
        siteLogo
        siteUrl
        author
      }
    }
  }
`

export default Homepage
