import React from 'react'
import { css } from '@emotion/react'
import { ParallaxLayer } from '@react-spring/parallax'

export interface DividerProps {
  speed: number
  offset: number
  children?: React.ReactNode
  bg?: string
  fill?: string
  clipPath?: string
  className?: string
  factor?: number
}

function Divider({
  speed,
  offset,
  factor = 1,
  bg = ``,
  fill = ``,
  clipPath = ``,
  children = null,
  className = ``,
}: React.PropsWithChildren<DividerProps>) {
  return (
    <ParallaxLayer
      css={css({
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: bg,
        backgroundColor: bg,
        clipPath: clipPath,
        '#contact-wave': {
          color: fill,
          fill: 'currentColor',
        },
      })}
      speed={speed}
      offset={offset}
      factor={factor}
      className={className}
    >
      {children}
    </ParallaxLayer>
  )
}

export default Divider
