import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="React Library - react-icd10" link="https://github.com/pfftdammitchris/react-icd10" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Search the International Classification of Diseases table
    </ProjectCard>
    <ProjectCard title="React Library - react-csheets" link="https://pfftdammitchris.github.io/react-csheets-demo/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Create your own customized cheatsheet using interactive snippets with
  different sets of themes available. Print your cheat sheet in PDF or JPEG.
    </ProjectCard>
    <ProjectCard title="Blog - jsmanifest" link="https://jsmanifest.com" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  JavaScript and its Technologies
    </ProjectCard>
    <ProjectCard title="healthfulinspired" link="https://healthfulinspired.com/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Health, wellness.
    </ProjectCard>
    <ProjectCard title="React Library - react-csheets" link="https://github.com/pfftdammitchris/react-cheatsheets" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Create cheat sheets using React
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      