import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Experience`}</h2>
    <br />
    <hr />
    <br />
    <h4>{`Team Lead - Web Developer at AiTmed`}</h4>
    <h5>{`December 2018 to Present`}</h5>
    <ul>
      <li parentName="ul">{`Built the company's website and web applications from scratch using the latest industry standard technologies in Node.js using JavaScript, HTML and CSS with React.js.`}</li>
      <li parentName="ul">{`Led and worked with a team of Web Developers by providing direction, guidance, contributions to best practices and code design patterns to accomplish projects effectively and efficiently.`}</li>
      <li parentName="ul">{`Self built and implemented a Software Development Kit private NPM package for the company from scratch with TypeScript based on backend HTTP APIs, securing a firm long-term support for developing applications quicker and more efficiently for future projects.`}</li>
      <li parentName="ul">{`Written documentation for the Software Development Kit.`}</li>
      <li parentName="ul">{`Assist chief officers in hiring decisions of candidates for the web development team.`}</li>
      <li parentName="ul">{`Search Engine Optimization(SEO)`}</li>
    </ul>
    <br />
    <h4>{`Front End Engineer at AiTmed`}</h4>
    <h5>{`September 2018 to December 2018`}</h5>
    <p>{`Develop front-end web applications with JavaScript, HTML, and CSS using React.js . Search Engine Optimization (SEO)`}</p>
    <br />
    <h4>{`Website Owner `}{`[Deprecated]`}{` at Healthful&Inspired`}</h4>
    <h5>{`March 2018 to December 2018`}</h5>
    <ul>
      <li parentName="ul">{`Develop front and back-end using React.js (HTML5, CSS3, JavaScript).`}</li>
      <li parentName="ul">{`Search Engine Optimization (SEO).`}</li>
      <li parentName="ul">{`Created user flows and sitemaps to architect the code flow.`}</li>
      <li parentName="ul">{`Customer Engagement Design. Social Media Monetization Techniques.`}</li>
      <li parentName="ul">{`Simultaneously Managing Several Reporting Tools.`}</li>
      <li parentName="ul">{`Automation for Business Flow.`}</li>
      <li parentName="ul">{`Affiliate/Email Marketing.`}</li>
      <li parentName="ul">{`Amazon Web Services.`}</li>
      <li parentName="ul">{`Traffic Building`}</li>
    </ul>
    <br />
    <h4>{`IT Support Specialist at Continental Agency Inc.`}</h4>
    <h5>{`August 2015 to August 2018`}</h5>
    <ul>
      <li parentName="ul">{`Installing and configuring computer hardware, software, systems, networks, printers and scanners. - Troubleshoot system problems, diagnosing and solving hardware or software faults.`}</li>
      <li parentName="ul">{`Responding in a timely manner to service issues and requests.`}</li>
      <li parentName="ul">{`Providing technical support across the company.`}</li>
      <li parentName="ul">{`Automate daily reports sent to division team managers.`}</li>
      <li parentName="ul">{`Replace parts as required`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      