import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const SocialMediaButtonGroup = makeShortcode("SocialMediaButtonGroup");
const SocialMediaButton = makeShortcode("SocialMediaButton");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hi, I'm Christopher`}</h1>
    <Box my={2} mdxType="Box" />
    <h6>{`I am creating state-of-the-art web experiences as a Full Stack Engineer`}</h6>
    <Box my={5} mdxType="Box" />
    <SocialMediaButtonGroup size="xs" spacing={5} mdxType="SocialMediaButtonGroup">
  <SocialMediaButton name="github" bg="#fff" url="https://github.com/pfftdammitchris" mdxType="SocialMediaButton" />
  <SocialMediaButton name="linked.in" url="https://www.linkedin.com/in/christopher-trann/" mdxType="SocialMediaButton" />
  <SocialMediaButton name="dev.to" url="https://dev.to/jsmanifest" mdxType="SocialMediaButton" />
  <SocialMediaButton name="medium" bg="#fff" url="https://medium.com/@jsmanifest" mdxType="SocialMediaButton" />
  <SocialMediaButton name="twitter" url="https://twitter.com/jsmanifest" mdxType="SocialMediaButton" />
    </SocialMediaButtonGroup>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      