import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Badge } from '@chakra-ui/react';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <ul>
      <li parentName="ul">{`JavaScript `}<Badge mdxType="Badge">{`99%`}</Badge></li>
      <li parentName="ul">{`React `}<Badge mdxType="Badge">{`99%`}</Badge></li>
      <li parentName="ul">{`Node.js `}<Badge mdxType="Badge">{`90%`}</Badge></li>
      <li parentName="ul">{`CSS `}<Badge mdxType="Badge">{`70%`}</Badge></li>
      <li parentName="ul">{`HTML `}<Badge mdxType="Badge">{`80%`}</Badge></li>
      <li parentName="ul">{`Git `}<Badge mdxType="Badge">{`60%`}</Badge></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      