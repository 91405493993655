import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Contact`}</h2>
    <h3>{`Find me on social media:`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://dev.to/@jsmanifest"
        }}>{`dev.to`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://jsmanifest.com"
        }}>{`jsmanifest`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.linkedin.com/in/christopher-trann/"
        }}>{`linkedin`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://medium.com/@jsmanifest"
        }}>{`medium`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://twitter.com/@pfftdammitchris"
        }}>{`twitter`}</a></li>
    </ul>
    <h3>{`Contact me`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      