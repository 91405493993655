import React from 'react'
import { GridItem, List, SimpleGrid, Text } from '@chakra-ui/react'
import Box from 'components/Box'
import Experience from 'sections/experience'
import Skills from 'sections/skills'
import Divider from '../elements/divider'
import Inner from '../elements/inner'
import Content from '../elements/content'
import Svg from './svg'
import { UpDown, UpDownWide } from '../styles/animations'

const About = ({ offset, factor = 1 }: { offset: number; factor?: number }) => (
  <div>
    <Divider
      bg="divider"
      clipPath="polygon(0 16%, 100% 4%, 100% 82%, 0 94%)"
      speed={0.2}
      offset={offset}
      factor={factor}
    />
    <Divider speed={0.1} offset={offset} factor={factor}>
      <UpDown>
        <Svg
          icon="box"
          hiddenMobile
          width={6}
          color="icon_blue"
          left="50%"
          top="75%"
        />
        <Svg
          icon="upDown"
          hiddenMobile
          width={8}
          color="icon_darkest"
          left="70%"
          top="20%"
        />
        <Svg
          icon="triangle"
          width={8}
          stroke="HighlightText"
          color="icon_darkest"
          left="25%"
          top="5%"
        />
        <Svg
          icon="upDown"
          hiddenMobile
          width={24}
          color="icon_orange"
          left="80%"
          top="80%"
        />
      </UpDown>
      <UpDownWide>
        <Svg
          icon="arrowUp"
          hiddenMobile
          width={16}
          color="icon_purple"
          left="5%"
          top="80%"
        />
        <Svg
          icon="triangle"
          width={12}
          stroke="AppWorkspace"
          color="icon_brightest"
          left="95%"
          top="50%"
        />
        <Svg
          icon="circle"
          hiddenMobile
          width={6}
          color="icon_brightest"
          left="85%"
          top="15%"
        />
        <Svg
          icon="upDown"
          hiddenMobile
          width={8}
          color="icon_darkest"
          left="45%"
          top="10%"
        />
      </UpDownWide>
      <Svg
        icon="circle"
        hiddenMobile
        width={6}
        color="icon_brightest"
        left="4%"
        top="20%"
      />
      <Svg icon="circle" width={12} color="icon_darkest" left="70%" top="60%" />
      <Svg icon="box" width={6} color="icon_orange" left="10%" top="10%" />
      <Svg icon="box" width={12} color="icon_darkest" left="20%" top="30%" />
      <Svg
        icon="hexa"
        width={8}
        stroke="coral"
        color="icon_darkest"
        left="80%"
        top="70%"
      />
    </Divider>
    <Content speed={0.4} offset={offset} factor={factor}>
      <Inner>
        <SimpleGrid alignItems="center" columns={2} p={5}>
          <GridItem>
            <Box flexBasis="60%">
              <Text>
                I am a Full Stack Engineer focusing more on the Front End and
                modern tech such as React.
              </Text>
            </Box>
          </GridItem>
          <GridItem>
            <List
              display="flex"
              flexDir="column"
              justifyContent="space-between"
            >
              <Skills />
            </List>
          </GridItem>
        </SimpleGrid>
        <Box display="flex" flexDir="column" p={5}>
          <Experience />
        </Box>
      </Inner>
    </Content>
  </div>
)

export default About
