import {
  css as emotionCss,
  ArrayInterpolation,
  Interpolation,
} from '@emotion/react'
import type { CSSInterpolation } from '@emotion/serialize'
import React from 'react'

export interface InnerProps {
  css?: CSSInterpolation[]
  className?: string
  children: React.ReactNode
}

function Inner({
  className = ``,
  css: cssProp,
  children,
}: React.PropsWithChildren<InnerProps>) {
  return (
    <div
      css={emotionCss({ width: '100%', textAlign: 'left' }, cssProp)}
      className={className}
    >
      {children}
    </div>
  )
}

export default Inner
